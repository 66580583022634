import React from "react";
import Aboutus from "../components/Aboutus";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Mobile from "../components/Mobile";
import Nav from "../components/Nav";
import Ouraim from "../components/Ouraim";
import Services from "../components/Services";
import Tgoods from "../components/Tgoods";
import Whychoose from "../components/Whychoose";

const Routing = () => {
  return (
    <div>
      <div>
        <Nav />
        <Header />
        <Aboutus />
        <Services />
        <Tgoods />
        <Whychoose />
        <Ouraim />
        <Mobile />
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

export default Routing;
